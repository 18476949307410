import * as Yup from 'yup';

import * as messages from '../messages';

export default Yup.object().shape({
  fullName: Yup.string()
    .required(messages.REQUIRED),
  type: Yup.string()
    .required(messages.REQUIRED),
  fee: Yup.number()
    .required(messages.REQUIRED),
  paymentDate: Yup.date()
    .max(new Date())
    .required(messages.REQUIRED),
  notes: Yup.string()
    .max(400)
});