import {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {maxBy} from 'lodash';

import {CertificationYearPage, CertsAppContext, CertsAppContextType} from './CertsAppContext';

import {CertificationYear, CertificationYearsMap} from '../types';
import CertificationStatus from '../types/CertificationStatus';
import {certificationStatusApi} from '../api';

type Props = {
  children: ReactNode
}

const CertsAppProvider = ({
                            children
                          }: Props) => {
  const [certificationYearsMap, setCertificationYearsMap] = useState<CertificationYearsMap>({
    courses: [],
    myCertifications: [],
    payments: [],
    escrows: [],
    certificationLevelLimits: []
  });

  const [currentYearCertificationStatus, setCurrentYearCertificationStatus] = useState<CertificationStatus | null>(null);

  const defaultCoursesYear = useMemo(
    () => certificationYearsMap.courses.find((certificationYear: CertificationYear) => certificationYear.defaultCoursesYear),
    [certificationYearsMap]
  );

  const defaultMyCertificationsYear = useMemo(() => {
      return certificationYearsMap.myCertifications.find((certificationYear: CertificationYear) => certificationYear.defaultMyCertificationsYear);
    }, [certificationYearsMap]
  );

  const maxMyCertificationsYear = useMemo(() => {
    return maxBy(certificationYearsMap.myCertifications, 'value');
  }, [certificationYearsMap]);

  const defaultPaymentsYear = useMemo(
    () => certificationYearsMap.payments.find((certificationYear: CertificationYear) => certificationYear.defaultPaymentsYear),
    [certificationYearsMap]
  );

  const defaultEscrowsYear = useMemo(
    () => certificationYearsMap.escrows.find((certificationYear: CertificationYear) => certificationYear.defaultEscrowsYear),
    [certificationYearsMap]
  );

  const defaultCertificationLevelLimitsYear = useMemo(
    () => certificationYearsMap.certificationLevelLimits.find((certificationYear: CertificationYear) => certificationYear.defaultCertificationLevelLimitsYear),
    [certificationYearsMap]
  );

  useEffect(() => {
    const loadDefaultYearCertificationStatus = async () => {
      try {
        const certificationStatus = await certificationStatusApi.findCurrentUserStatusByYear(
          Number(defaultMyCertificationsYear?.value)
        );
        setCurrentYearCertificationStatus(certificationStatus);
      } catch (e) {
        console.error(e);
      }
    };

    void loadDefaultYearCertificationStatus();
  }, [defaultMyCertificationsYear]);

  const getCertificationYearToDisplay = useCallback((certificationYearPage: CertificationYearPage): CertificationYear => {
    const urlCertificationYear = Number(new URLSearchParams(window.location.search).get('certificationYear'));
    const certificationYears = certificationYearsMap[certificationYearPage];
    const shouldDisplayMaxYear = currentYearCertificationStatus &&
      currentYearCertificationStatus.shouldDisplayLatestCertificationYear;

    const defaultCertificationYears: {
      [key in CertificationYearPage]: CertificationYear
    } = {
      courses: defaultCoursesYear as CertificationYear,
      myCertifications: (shouldDisplayMaxYear ? maxMyCertificationsYear : defaultMyCertificationsYear) as CertificationYear,
      payments: defaultPaymentsYear as CertificationYear,
      escrows: defaultEscrowsYear as CertificationYear,
      certificationLevelLimits: defaultCertificationLevelLimitsYear as CertificationYear
    };

    const foundCertificationYear = certificationYears.find(certificationYear => certificationYear.value === urlCertificationYear);
    return foundCertificationYear ? foundCertificationYear : defaultCertificationYears[certificationYearPage];
  }, [
    currentYearCertificationStatus,
    maxMyCertificationsYear,
    certificationYearsMap,
    defaultCertificationLevelLimitsYear,
    defaultCoursesYear,
    defaultEscrowsYear,
    defaultMyCertificationsYear,
    defaultPaymentsYear
  ]);

  const value: CertsAppContextType = {
    defaultCoursesYear: defaultCoursesYear ? defaultCoursesYear : null,
    defaultMyCertificationsYear: defaultMyCertificationsYear ? defaultMyCertificationsYear : null,
    defaultPaymentsYear: defaultPaymentsYear ? defaultPaymentsYear : null,
    defaultEscrowsYear: defaultEscrowsYear ? defaultEscrowsYear : null,
    defaultCertificationLevelLimitsYear: defaultCertificationLevelLimitsYear ? defaultCertificationLevelLimitsYear : null,
    certificationYearsMap,
    setCertificationYearsMap,
    getCertificationYearToDisplay
  };

  return (
    <CertsAppContext.Provider value={value}>
      {children}
    </CertsAppContext.Provider>
  );
};

export default CertsAppProvider;