import * as Yup from 'yup';

import {YupFile} from '@reasoncorp/kyber-js';

import * as messages from '../messages';

const courseFormSchema =  (shouldValidateSingleCourse: boolean) => {
  if(shouldValidateSingleCourse) {
    return Yup.object().shape({
      singleOrMultipleCourses: Yup.string()
        .required(messages.REQUIRED),
      certificationYear: Yup.number()
        .required(messages.REQUIRED),
      courseNumber: Yup.string()
        .required(messages.REQUIRED),
      name: Yup.string()
        .required(messages.REQUIRED),
      courseDate: Yup.date()
        .nullable(),
      approvedHours: Yup.number()
        .required(messages.REQUIRED),
      instructor: Yup.string()
        .required(messages.REQUIRED),
      organization: Yup.string()
        .required(messages.REQUIRED),
      contact: Yup.string(),
      locked: Yup.bool(),
      stcUpdate: Yup.bool()
        .required(messages.REQUIRED)
        .nullable(),
      classFormat: Yup.string()
        .required(messages.REQUIRED)
        .oneOf(['LIVE_SESSION', 'SELF_PACED', null])
        .nullable()
    });
  } else {
    return Yup.object().shape({
      singleOrMultipleCourses: Yup.string()
        .required(messages.REQUIRED),
      file: new YupFile().acceptedFileTypes(['XLS', 'XLSX', 'XLSM'], messages.BAD_FILE_FORMAT_EXCEL)
        .maxFileSize(10000000, messages.MAX_FILE_SIZE)
        .schema()
        .required(messages.REQUIRED)
    })
  }
}

export default courseFormSchema;