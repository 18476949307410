import {useMemo} from 'react';
import {Button, Col, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';

import {CertificationStatus, CertificationYear, CertificationYearFormFields, LoggedCourse} from '../../types';
import {CertificationYearSelect} from '../shared';
import {useCertsAppContext} from '../../hooks';

type Props = {
  certificationStatus?: CertificationStatus
  handleViewCertificateClick: () => void
  handleCertificationYearChange: (values: CertificationYearFormFields,
                                  formikHelpers: FormikHelpers<CertificationYearFormFields>) => Promise<void>
  handleRenewByMailClick: () => void
  handleRenewOnlineClick: () => void
  loading: boolean
  selectedCertificationYear: CertificationYear
  toggleLogCourseModal: (loggedCourse?: LoggedCourse) => Promise<void>
}

const MyCertificationsToolbar = ({
                                   certificationStatus,
                                   handleViewCertificateClick,
                                   handleCertificationYearChange,
                                   handleRenewByMailClick,
                                   handleRenewOnlineClick,
                                   loading,
                                   selectedCertificationYear,
                                   toggleLogCourseModal
                                 }: Props) => {
  const {certificationYearsMap} = useCertsAppContext();

  const buttonState = useMemo(() => {
    return certificationStatus ? {
      showLogCourseButton: selectedCertificationYear.loggingCoursesAllowed && !certificationStatus.requiredHoursMet,
      showPaymentButtons: selectedCertificationYear.paymentsAllowed && certificationStatus.requiredHoursMet && !certificationStatus.hasCertificate,
      showViewCertificateButton: certificationStatus.requiredHoursMet && certificationStatus.hasCertificate
    } : {
      showLogCourseButton: false,
      showPaymentButtons: false,
      showViewCertificateButton: false
    };
  }, [
    certificationStatus,
    selectedCertificationYear
  ]);

  return (
    <Row className="d-flex justify-content-between align-items-center">
      <Col md="2">
        <CertificationYearSelect selectedCertificationYear={selectedCertificationYear.value}
                                 certificationYears={certificationYearsMap.myCertifications}
                                 onChange={handleCertificationYearChange}
                                 disabled={loading}/>
      </Col>
      <Col md="10" className="d-flex justify-content-md-end">
        {buttonState.showLogCourseButton &&
          <Button color="primary"
                  onClick={() => toggleLogCourseModal()}
                  disabled={loading}>
            Log course
          </Button>}
        {buttonState.showPaymentButtons &&
          <Button color="primary mr-2"
                  disabled={loading}
                  onClick={handleRenewOnlineClick}>
            Renew Online
          </Button>}
        {buttonState.showPaymentButtons &&
          <Button color="primary"
                  disabled={loading}
                  onClick={handleRenewByMailClick}>
            Renew By Mail
          </Button>}
        {buttonState.showViewCertificateButton &&
          <Button color="primary"
                  disabled={loading}
                  onClick={handleViewCertificateClick}>
            View Certificate
          </Button>
        }
      </Col>
    </Row>
  );
};

export default MyCertificationsToolbar;