import {User} from '@reasoncorp/kyber-js';

import {certsApi} from './apiUtils';
import {Escrow, EscrowApplyOnBehalfFormFields} from '../types';

export const findCurrentUserEscrowByYear = (certificationYear: number): Promise<Escrow> => certsApi.getWithJsonResponse(
  'escrows/current-user',
  {
    queryParams: {certificationYear}
  }
);

export const findAllBy = (certificationYear: number): Promise<Escrow[]> => certsApi.getWithJsonResponse(
  'escrows',
  {
    queryParams: {certificationYear}
  }
);

export const create = (escrowApplicationRequest: FormData): Promise<Escrow> => certsApi.postWithJsonResponse(
  'escrows/current-user',
  {
    body: escrowApplicationRequest
  }
);

export const createOnBehalf = (escrowApplicationRequest: EscrowApplyOnBehalfFormFields): Promise<Escrow> => certsApi.postWithJsonResponse(
  'escrows',
  {
    body: JSON.stringify(escrowApplicationRequest)
  }
);

export const findAvailableUsers = (certificationYear: number): Promise<User[]> => certsApi.getWithJsonResponse(
  `escrows/available-users/${certificationYear}`
);

export const approve = (id: number): Promise<Escrow> => certsApi.patchWithJsonResponse(
  `escrows/${id}/approve`
);

export const deny = (id: number, comments: string): Promise<Escrow> => certsApi.patchWithJsonResponse(
  `escrows/${id}/deny`,
  {
    body: JSON.stringify({comments})
  }
);

export const removeFromEscrow = (id: number): Promise<Escrow> => certsApi.patchWithJsonResponse(
  `escrows/${id}/remove-from-escrow`
);