import {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {escapeRegExp} from 'lodash';
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  ButtonIcon,
  ConfirmationModal,
  CustomTable,
  Duration,
  DurationType,
  ProgressIndicator,
  ProgressModal,
  ResultsLimiter,
  useAlerts
} from '@reasoncorp/kyber-js';

import {courseApi, reportApi} from '../api';
import * as messages from '../messages';
import {useCertsAppContext} from '../hooks';
import {CertificationYearSelect, SearchInput} from '../components/shared';
import {AttendeesModal, CourseModal, LogOnBehalfOfModal} from '../components/course';
import {CertificationYearFormFields, Course} from '../types';

const Courses = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const {certificationYearsMap, getCertificationYearToDisplay} = useCertsAppContext();
  const modifiableCertificationYears = certificationYearsMap.courses
    .filter(certificationYear => certificationYear.courseModificationsAllowed)
    .map(certificationYear => certificationYear.value);
  const selectedCertificationYear = getCertificationYearToDisplay('courses');
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, exportingReport: false});
  const [searchText, setSearchText] = useState('');
  const [courses, setCourses] = useState<Course[]>([]);
  const [resultLimit, setResultLimit] = useState<number | null>(50);
  const [selectedCourse, setSelectedCourse] = useState<Course | undefined>(undefined);
  const [courseModalState, setCourseModalState] = useState<{
    isOpen: boolean,
    operation: 'Add' | 'Edit',
    isDuplicating: boolean
  }>({
    isOpen: false,
    operation: 'Add',
    isDuplicating: false
  });
  const [showAttendeesModal, setShowAttendeesModal] = useState(false);
  const [showLogOnBehalfOfModal, setShowLogOnBehalfOfModal] = useState(false);
  const [showCourseDeleteConfirmationModal, setShowCourseDeleteConfirmationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const disableEditingCourses = useMemo(() => {
    return selectedCertificationYear && !selectedCertificationYear.courseModificationsAllowed;
  }, [
    selectedCertificationYear
  ]);

  const filteredCourses = useMemo(() => {
    return courses.filter(course => {
      if (!searchText) {
        return true;
      } else {
        const searchTermRegexp = RegExp(escapeRegExp(searchText), 'i');
        return searchTermRegexp.test(course.courseNumber) ||
          searchTermRegexp.test(course.name) ||
          searchTermRegexp.test(course.instructor) ||
          searchTermRegexp.test(course.organization) ||
          searchTermRegexp.test(course.approvedHours.toString()) ||
          searchTermRegexp.test(course.courseDateDisplay) ||
          searchTermRegexp.test(course.contact);
      }
    });
  }, [
    courses,
    searchText
  ]);

  const handleCertificationYearChange = useCallback(async (values: CertificationYearFormFields,
                                                           formikHelpers: FormikHelpers<CertificationYearFormFields>) => {
    setLoadingState(loadingState => ({...loadingState, loading: true}));
    const searchParams = new URLSearchParams();
    searchParams.set('certificationYear', values.certificationYear.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`);

    try {
      const courses = await courseApi.findAllBy(Number(values.certificationYear), true);
      setCourses(courses);
      setLoadingState(loadingState => ({...loadingState, loading: false}));
    } catch (error) {
      setLoadingState(loadingState => ({...loadingState, loading: false, loadError: true}));
      showErrorAlert(messages.COURSES_LOAD_FAILED);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [
    location.pathname,
    navigate,
    showErrorAlert
  ]);

  const handleTotalHoursReportClick = useCallback(async () => {
    try {
      setLoadingState(loadingState => ({...loadingState, exportingReport: true}));
      await reportApi.totalHours(selectedCertificationYear.value);
      setLoadingState(loadingState => ({...loadingState, exportingReport: false}));
      showSuccessAlert(messages.REPORT_EXPORT_SUCCESSFUL);
    } catch (e) {
      setLoadingState(loadingState => ({...loadingState, exportingReport: false}));
      showErrorAlert(messages.REPORT_EXPORT_FAILED);
    }
  }, [
    selectedCertificationYear.value,
    showErrorAlert,
    showSuccessAlert
  ]);

  const toggleAttendeesModal = useCallback(async (course: Course, attendeesRemoved?: boolean) => {
    if (!showAttendeesModal) {
      setSelectedCourse(course);
      setShowAttendeesModal(true);
    } else {
      if (attendeesRemoved) {
        const newCourse = await courseApi.findById(course.id);
        const updatedCourses = courses.map(currentCourse => course.id === currentCourse.id ? newCourse : currentCourse);
        setCourses(updatedCourses);
      }
      setShowAttendeesModal(false);
    }
  }, [
    courses,
    showAttendeesModal
  ]);

  const toggleLogOnBehalfOfModal = useCallback(async (course?: Course) => {
    if (!showLogOnBehalfOfModal) {
      setSelectedCourse(course);
      setShowLogOnBehalfOfModal(true);
    } else {
      if (course) {
        const newCourse = await courseApi.findById(course.id);
        const updatedCourses = courses.map(currentCourse => course.id === currentCourse.id ? newCourse : currentCourse);
        setCourses(updatedCourses);
      }
      setShowLogOnBehalfOfModal(false);
    }
  }, [
    courses,
    showLogOnBehalfOfModal
  ]);

  const handleImportSubmit = useCallback(async (newCourses: Course[]) => {
    setCourses([
      ...courses,
      ...newCourses
    ]);
    setCourseModalState(courseModalState => ({...courseModalState, isOpen: false}));
  }, [
    courses
  ]);

  const toggleCourseModal = useCallback((operation: 'Add' | 'Edit', course?: Course) => {
    if (!courseModalState.isOpen) {
      // The blocks below handle opening the modal in either Add or Edit mode
      if (operation === 'Add') {
        setSelectedCourse(undefined);
        setCourseModalState({isOpen: true, operation, isDuplicating: false});
      }
      if (operation === 'Edit') {
        setSelectedCourse(course);
        setCourseModalState({isOpen: true, operation, isDuplicating: false});
      }
    } else {
      // The conditional blocks below handle if a successful add, successful edit, or if a cancel happened.
      if (operation === 'Add' && course) {
        // Don't add course current list of courses if the certification certificationYear of that course is not the same as the currently selected certification certificationYear
        if (selectedCertificationYear.value === course.certificationYear) {
          setCourses([
            ...courses,
            course
          ]);
        }
      } else if (operation === 'Edit' && course) {
        setCourses(prevCourses => {
          const index = prevCourses.findIndex(item => item.id === course.id);
          if (index !== -1) {
            prevCourses[index] = course;
          }
          return prevCourses;
        });
      }
      setCourseModalState(courseModalState => ({...courseModalState, isOpen: false}));
    }
  }, [
    courses,
    courseModalState.isOpen,
    selectedCertificationYear.value
  ]);

  const toggleCourseDeleteDialog = useCallback(async (course?: Course) => {
    if (showCourseDeleteConfirmationModal) {
      // If course has a value while the modal is open then attempt to delete it
      if (course) {
        try {
          setIsSubmitting(true);
          await courseApi.remove(course.id);
          const coursesWithoutRemovedCourse = courses.filter(c => c.id !== course.id);
          setCourses(coursesWithoutRemovedCourse);
          showSuccessAlert(messages.COURSE_DELETE_SUCCESSFUL);
        } catch (error) {
          showErrorAlert(messages.COURSE_DELETE_FAILED);
        } finally {
          setIsSubmitting(false);
          setShowCourseDeleteConfirmationModal(false);
        }
      } else {
        setShowCourseDeleteConfirmationModal(false);
      }
    } else {
      setSelectedCourse(course);
      setShowCourseDeleteConfirmationModal(true);
    }
  }, [
    courses,
    showCourseDeleteConfirmationModal,
    showErrorAlert,
    showSuccessAlert
  ]);

  const handleDuplicateClick = useCallback(() => {
    setCourseModalState({isOpen: false, operation: 'Add', isDuplicating: false});
    setTimeout(() => {
        setCourseModalState({isOpen: true, operation: 'Add', isDuplicating: true});
      }, Duration.of(0.5, DurationType.SECONDS)
    );
  }, []);

  const tableProps = useMemo(() => ({
    className: 'mb-0',
    headers: [
      {title: 'Number', sortKey: 'courseNumber', className: 'text-nowrap'},
      {title: 'Course', sortKey: 'name', className: 'text-nowrap'},
      {title: 'Class Format', sortKey: 'classFormat', className: 'text-center text-nowrap'},
      {title: 'Instructor', sortKey: 'instructor', className: 'text-nowrap'},
      {title: 'Organization', sortKey: 'organization', className: 'text-nowrap'},
      {title: 'Hours', sortKey: 'approvedHours', className: 'text-nowrap text-center'},
      {title: 'Course Date', sortKey: 'courseDate', className: 'text-nowrap text-center'},
      {title: 'Contact', sortKey: 'contact', className: 'text-nowrap'},
      {title: 'Locked', sortKey: 'locked', className: 'text-nowrap text-center'},
      {title: 'View Users', className: 'text-center text-nowrap'},
      {title: 'Log User', className: 'text-center text-nowrap'},
      {title: 'Edit Course', className: 'text-center text-nowrap', hide: disableEditingCourses},
      {title: 'Delete Course', className: 'text-center text-nowrap', hide: disableEditingCourses}
    ],
    initialSort: {sortKey: 'name', direction: 'asc' as const},
    items: filteredCourses,
    noResultsMessage: messages.COURSES_NOT_FOUND,
    resultLimit,
    renderRow: (course: Course) => {
      const {
        id,
        courseNumber,
        name,
        instructor,
        organization,
        approvedHours,
        courseDateDisplay,
        contact,
        locked,
        numberOfAttendees,
        classFormatDisplayValue
      } = course;

      const viewAttendeesButtonLabelText = `View ${name} (${instructor}) attendees`;
      const deleteCourseButtonLabelText = `Delete ${name} (${instructor})`;
      const editCourseButtonLabelText = `Edit ${name} (${instructor})`;
      const logOnBehalfOfButtonLabelText = `Log on behalf of ${name} (${instructor})`;

      return (
        <tr key={id}>
          <td className="align-middle">{courseNumber}</td>
          <td className="align-middle">{name}</td>
          <td className="align-middle">{classFormatDisplayValue}</td>
          <td className="align-middle">{instructor}</td>
          <td className="align-middle">{organization}</td>
          <td className="text-center align-middle">{approvedHours}</td>
          <td className="align-middle text-center">{courseDateDisplay}</td>
          <td className="align-middle">{contact}</td>
          <td className="align-middle text-center">
            {locked && <FontAwesomeIcon className="text-danger"
                                        icon="lock"
                                        title="Locked"
                                        aria-label="Locked"/>}
            {!locked && <FontAwesomeIcon className="text-success"
                                         icon="lock-open"
                                         title="Unlocked"
                                         aria-label="Unlocked"/>}
          </td>
          <td className="text-center align-middle">
            {!(numberOfAttendees === 0) && <ButtonIcon icon="users"
                                                       title={viewAttendeesButtonLabelText}
                                                       ariaLabel={viewAttendeesButtonLabelText}
                                                       className="text-secondary"
                                                       onClick={() => toggleAttendeesModal(course)}/>}
          </td>
          <td className="text-center align-middle">
            <ButtonIcon icon="user-plus"
                        title={logOnBehalfOfButtonLabelText}
                        ariaLabel={logOnBehalfOfButtonLabelText}
                        className="text-secondary"
                        onClick={() => toggleLogOnBehalfOfModal(course)}/>
          </td>
          {selectedCertificationYear.courseModificationsAllowed &&
            <td className="text-center align-middle">
              <ButtonIcon icon="cog"
                          title={editCourseButtonLabelText}
                          ariaLabel={editCourseButtonLabelText}
                          className="text-primary"
                          onClick={() => toggleCourseModal('Edit', course)}/>
            </td>
          }
          {selectedCertificationYear.courseModificationsAllowed &&
            <td className="text-center align-middle">
              <ButtonIcon icon="trash"
                          title={deleteCourseButtonLabelText}
                          ariaLabel={deleteCourseButtonLabelText}
                          className="text-danger"
                          onClick={() => toggleCourseDeleteDialog(course)}/>
            </td>
          }
        </tr>
      );
    }
  }), [
    filteredCourses,
    disableEditingCourses,
    resultLimit,
    selectedCertificationYear.courseModificationsAllowed,
    toggleAttendeesModal,
    toggleCourseDeleteDialog,
    toggleCourseModal,
    toggleLogOnBehalfOfModal
  ]);

  useEffect(() => {
    const loadCourses = async () => {
      try {
        const courses = await courseApi.findAllBy(selectedCertificationYear.value, true);
        setCourses(courses);
        setLoadingState(loadingState => ({...loadingState, loading: false}));
      } catch (error) {
        setLoadingState(loadingState => ({...loadingState, loading: false, loadError: true}));
        showErrorAlert(messages.COURSES_LOAD_FAILED);
      }
    };

    void loadCourses();
  }, [
    selectedCertificationYear,
    showErrorAlert
  ]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid className="Courses">
        <Row className="d-flex justify-content-between align-items-center">
          <Col md="2">
            <CertificationYearSelect selectedCertificationYear={selectedCertificationYear.value}
                                     certificationYears={certificationYearsMap.courses}
                                     onChange={handleCertificationYearChange}
                                     disabled={loadingState.loading || loadingState.loadError}/>
          </Col>
          <Col md="10" className="d-flex justify-content-md-end">
            <Button className={selectedCertificationYear.courseModificationsAllowed ? 'mr-2' : ''}
                    color="primary"
                    onClick={() => handleTotalHoursReportClick()}
                    disabled={loadingState.loading || loadingState.loadError}>
              Total Hours Report
            </Button>
            {selectedCertificationYear.courseModificationsAllowed &&
              <Button color="primary"
                      onClick={() => toggleCourseModal('Add')}
                      disabled={loadingState.loading || loadingState.loadError}>
                Add Course
              </Button>
            }
          </Col>
        </Row>
        {loadingState.loading && <ProgressIndicator/>}
        {!loadingState.loading && <>
          <Card>
            <CardHeader className="bg-secondary text-uppercase text-white">
              Approved Continuing Education Renewal Courses
            </CardHeader>
            <CardBody>
              <SearchInput onSubmit={setSearchText}
                           disabled={loadingState.loading || loadingState.loadError}/>
              <CustomTable {...tableProps}/>
            </CardBody>
          </Card>

          <ResultsLimiter message="Courses to Show:"
                          recordName="Course"
                          pluralRecordName="Courses"
                          limitOptions={[
                            {displayValue: '50', value: 50},
                            {displayValue: '100', value: 100},
                            {displayValue: '250', value: 250},
                            {displayValue: 'All', value: null}
                          ]}
                          resultLimit={resultLimit}
                          totalRecords={filteredCourses.length}
                          handleClick={setResultLimit}/>

          {selectedCourse && <AttendeesModal isOpen={showAttendeesModal}
                                             course={selectedCourse}
                                             onToggle={toggleAttendeesModal}/>}

          {selectedCourse && <LogOnBehalfOfModal isOpen={showLogOnBehalfOfModal}
                                                 course={selectedCourse}
                                                 onToggle={toggleLogOnBehalfOfModal}/>}

          <CourseModal isOpen={courseModalState.isOpen}
                       course={selectedCourse}
                       isDuplicating={courseModalState.isDuplicating}
                       operation={courseModalState.operation}
                       certificationYears={modifiableCertificationYears}
                       onImportSubmit={handleImportSubmit}
                       onDuplicateClick={handleDuplicateClick}
                       onToggle={toggleCourseModal}/>

          {selectedCourse &&
            <ConfirmationModal isOpen={showCourseDeleteConfirmationModal}
                               size="lg"
                               title="Delete Course"
                               confirmButtonText="Yes"
                               confirmButtonColor="success"
                               cancelButtonText="No"
                               confirmCallback={() => toggleCourseDeleteDialog(selectedCourse)}
                               cancelCallback={() => toggleCourseDeleteDialog()}
                               confirmButtonDisabled={isSubmitting}
                               cancelButtonDisabled={isSubmitting}>
              <p>
                Are you sure you want to delete the course <span className="text-danger">{selectedCourse.name}</span>?
              </p>
            </ConfirmationModal>
          }
          <ProgressModal isOpen={loadingState.exportingReport}
                         title="Generating Total Hours Report"
                         content="Report is being generated. Please do not refresh the page, as this could take a few moments."/>
        </>}
      </Container>
    );
  }
};

export default Courses;