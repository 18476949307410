import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {escapeRegExp} from 'lodash';

import {
  CustomTable,
  FormikCheckboxGroup,
  FormikFileInput,
  FormikSearchInput,
  StatefulButton,
  useAlerts
} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {courseApi, loggedCourseApi} from '../../api';
import {logCourseFormSchema} from '../../schema';
import {CertificationStatus, CertificationYear, Course, LoggedCourse} from '../../types';
import {LogCourseFormFields} from '../../types/forms';

type Props = {
  isOpen: boolean
  certificationYear: CertificationYear
  certificationStatus: CertificationStatus
  onToggle: (loggedCourse?: LoggedCourse) => void
}

const LogCourseModal = ({
                          isOpen,
                          certificationYear,
                          certificationStatus,
                          onToggle
                        }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [coursesToDisplay, setCoursesToDisplay] = useState<Course[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);
  const initialValues: LogCourseFormFields = useMemo(() => {
    return {
      courseId: null,
      proofOfCompletionFile: null
    };
  }, []);

  const filteredCourses = useMemo(() => {
    return coursesToDisplay.filter(course => {
      if (!searchText) {
        return true;
      } else {
        const searchTermRegexp = RegExp(escapeRegExp(searchText), 'i');

        return searchTermRegexp.test(course.name) ||
          searchTermRegexp.test(course.courseDateDisplay) ||
          searchTermRegexp.test(course.classFormatDisplayValue ?? '') ||
          searchTermRegexp.test(course.courseNumber ?? '') ||
          searchTermRegexp.test(course.instructor ?? '');
      }
    });
  }, [
    coursesToDisplay,
    searchText
  ]);

  const handleSubmit = useCallback(async (values: LogCourseFormFields,
                                          formikHelpers: FormikHelpers<LogCourseFormFields>) => {
    try {
      const logCourseRequest = new FormData();
      logCourseRequest.append('courseId', selectedCourseId ? selectedCourseId.toString() : '');
      logCourseRequest.append('proofOfCompletionFile', values.proofOfCompletionFile as File);

      const loggedCourse = await loggedCourseApi.create(logCourseRequest);
      formikHelpers.setSubmitting(false);
      showSuccessAlert(messages.LOGGED_COURSE_SUCCESSFUL);
      onToggle(loggedCourse);
    } catch (error) {
      formikHelpers.setSubmitting(false);
      showErrorAlert(messages.LOGGED_COURSE_FAILED);
      onToggle();
    } finally {
      setSelectedCourseId(null);
      setSearchText('');
      formikHelpers.resetForm();
    }
  }, [
    onToggle,
    showErrorAlert,
    showSuccessAlert,
    selectedCourseId
  ]);

  const handleClose = useCallback((formikProps: FormikProps<LogCourseFormFields>) => {
    formikProps.resetForm();
    setSelectedCourseId(null);
    setSearchText('');
    onToggle();
  }, [onToggle]);

  useEffect(() => {
    const loadCourses = async () => {
      if (certificationYear && certificationYear.loggingCoursesAllowed) {
        try {
          const courses = await courseApi.findAllBy(certificationYear.value, false);
          const loggedCourseIds = certificationStatus.loggedCourses
            .map(loggedCourse => loggedCourse.course.id);
          const coursesToDisplay = courses
            .filter(course => {
              const isSelfPacedAndAtMax = course.classFormat === 'SELF_PACED' &&
                !course.stcUpdate &&
                certificationStatus.atSelfPacedMax;
              return !loggedCourseIds.includes(course.id) && !isSelfPacedAndAtMax;
            });
          setCoursesToDisplay(coursesToDisplay);
        } catch (error) {
          showErrorAlert(messages.COURSES_LOAD_FAILED);
        }
      }
    };

    void loadCourses();
  }, [
    certificationYear,
    certificationStatus,
    showErrorAlert
  ]);

  const handleItemSelect = useCallback((courseId: number) => {
    const updatedCourseId = selectedCourseId === courseId ?
      null : courseId;
    setSelectedCourseId(updatedCourseId);
  }, [
    selectedCourseId,
    setSelectedCourseId
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      [
        {
          title: 'Select a continuing education course',
          className: 'text-nowrap text-left',
          colSpan: 6
        }
      ],
      [
        {
          title: '',
          className: 'text-nowrap text-center w-10'
        },
        {
          title: 'Course',
          className: 'text-nowrap w-20',
          sortKey: 'name'
        },
        {
          title: 'Course Number',
          className: 'text-nowrap w-20',
          sortKey: 'courseNumber'
        },
        {
          title: 'Class Format',
          className: 'text-nowrap w-20',
          sortKey: 'classFormat'
        },
        {
          title: 'Instructor',
          className: 'text-nowrap w-20',
          sortKey: 'instructor'
        },
        {
          title: 'Course Date',
          className: 'text-nowrap w-10',
          sortKey: 'courseDate'
        }
      ]],
    items: filteredCourses,
    noResultsMessage: messages.COURSES_NOT_FOUND,
    initialSort: {
      sortKey: 'name',
      direction: 'asc' as const
    },
    chainSort: true,
    resultsLimiterConfig: {
      message: 'Courses to Show:',
      recordName: 'Course',
      pluralRecordName: 'Courses',
      limitOptions: [
        {displayValue: '25', value: 25},
        {displayValue: '50', value: 50},
        {displayValue: 'All', value: null}
      ],
      initialResultLimit: 25
    },
    renderRow: (course: Course) => {
      return <tr key={course.id}>
        <td className="text-center align-middle w-5">
          <FormikCheckboxGroup disabled={selectedCourseId !== null && selectedCourseId !== course.id}
                               formGroupClass="mb-0"
                               checkboxes={[{
                                 name: `courses-${course.id}`,
                                 labelText: '',
                                 selected: course.id === selectedCourseId,
                                 ariaLabel: `Toggle select Course: ${course.name} - ${course.courseNumber} - ${course.instructor}`,
                                 onChange: () => handleItemSelect(course.id)
                               }]}/>
        </td>
        <td>{course.name}</td>
        <td>{course.courseNumber}</td>
        <td>{course.classFormatDisplayValue}</td>
        <td>{course.instructor}</td>
        <td>{course.courseDateDisplay}</td>
      </tr>;
    }
  }), [
    handleItemSelect,
    selectedCourseId,
    filteredCourses
  ]);

  return (
    <Formik initialValues={initialValues}
            initialErrors={{
              proofOfCompletionFile: messages.REQUIRED
            }}
            validationSchema={logCourseFormSchema}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={handleSubmit}>
      {(formikProps) => {
        return (
          <Modal className="LogCourseModal"
                 autoFocus={false}
                 backdrop="static"
                 isOpen={isOpen}
                 size="xl"
                 toggle={() => handleClose(formikProps)}>
            <ModalHeader toggle={() => handleClose(formikProps)}>
              Log Continuing Education Course
            </ModalHeader>
            <Form onSubmit={formikProps.handleSubmit}
                  autoComplete="off">
              <ModalBody>
                <Row className="mb-2">
                  <Col>
                    <FormikSearchInput disabled={false}
                                       aria-required
                                       onSubmit={setSearchText}/>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <CustomTable {...tableProps} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormikFileInput name="proofOfCompletionFile"
                                     aria-required
                                     labelText="Upload Proof of Completion"/>
                  </Col>
                </Row>
              </ModalBody>
            </Form>
            <ModalFooter className="pr-0 pl-0">
              <Row className="w-100 d-flex m-0">
                <Col className="align-self-center text-secondary font-weight-bold" sm="8">
                  You may complete a maximum of 8 hours of self-paced courses.
                </Col>
                <Col className="align-self-center justify-content-end d-flex" sm="4">
                  <StatefulButton activeState={formikProps.isSubmitting ? 'submitting' : 'default'}
                                  states={[
                                    {name: 'default', text: 'Log Course'},
                                    {name: 'submitting', text: 'Logging Course', icon: 'spinner', spinIcon: true}
                                  ]}
                                  color="success"
                                  className="mr-2"
                                  onClick={formikProps.submitForm}
                                  disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting || selectedCourseId === null}>
                  </StatefulButton>
                  <Button color="secondary"
                          onClick={() => handleClose(formikProps)}
                          disabled={formikProps.isSubmitting}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default memo(LogCourseModal);