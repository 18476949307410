import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik} from 'formik';
import {escapeRegExp} from 'lodash';
import * as Yup from 'yup';

import {CustomTable, FormikSearchInput, SelectTableCell, useAlerts, User} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {escrowApi} from '../../api';
import {Escrow, EscrowApplyOnBehalfFormFields} from '../../types';

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  certificationYear: number
  onSave: (escrow: Escrow) => void
}

const EscrowApplyOnBehalfModal = ({
                                    isOpen,
                                    setOpen,
                                    certificationYear,
                                    onSave
                                  }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [searchText, setSearchText] = useState('');
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        if (isOpen) {
          const availableUsers = await escrowApi.findAvailableUsers(certificationYear);
          setAvailableUsers(availableUsers);
        }
      } catch (e) {
        showErrorAlert(messages.USER_LOAD_FAILED);
      } finally {
        setIsLoading(false);
      }
    };

    void loadUsers();
  }, [
    isOpen,
    showErrorAlert,
    certificationYear
  ]);

  const filteredUsers = useMemo(() => {
    return availableUsers.filter(user => {
      if (!searchText) {
        return true;
      } else {
        const searchTermRegexp = RegExp(escapeRegExp(searchText), 'i');

        return searchTermRegexp.test(user.firstName) ||
          searchTermRegexp.test(user.lastName) ||
          searchTermRegexp.test(user?.certificationNumber ?? '');
      }
    });
  }, [
    availableUsers,
    searchText
  ]);

  const initialValues: EscrowApplyOnBehalfFormFields = useMemo(() => ({
    userId: null,
    certificationYear
  }), [
    certificationYear
  ]);

  const handleSubmit = useCallback(async () => {
    setOpen(false);
    setSearchText('');

    try {
      const escrow = await escrowApi.createOnBehalf({
        userId: selectedUserId as number,
        certificationYear
      });
      onSave(escrow);
      showSuccessAlert(messages.ESCROW_APPLY_ON_BEHALF_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.ESCROW_APPLY_ON_BEHALF_FAILURE);
    } finally {
      setSelectedUserId(null);
    }
  }, [
    setOpen,
    onSave,
    certificationYear,
    selectedUserId,
    showErrorAlert,
    showSuccessAlert
  ]);

  const handleClose = useCallback(() => {
    setSelectedUserId(null);
    setSearchText('');
    setOpen(false);
  }, [
    setOpen
  ]);

  const handleItemSelect = useCallback((userId: number) => {
    const updatedUserId = selectedUserId === userId ? null : userId;
    setSelectedUserId(updatedUserId);
  }, [
    selectedUserId
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      [
        {
          title: 'Select the user you would like to put into escrow',
          className: 'text-nowrap text-left',
          colSpan: 4,
        }
      ],
      [
        {
          title: '',
          className: 'text-nowrap text-center w-10'
        },
        {
          title: 'Last Name',
          className: 'text-nowrap w-30',
          sortKey: 'lastName'
        },
        {
          title: 'First Name',
          className: 'text-nowrap w-30',
          sortKey: 'firstName'
        },
        {
          title: 'Cert #',
          className: 'w-30 text-center',
          sortKey: 'certificationNumber'
        }
      ]
    ],
    items: filteredUsers,
    noResultsMessage: messages.USERS_NOT_FOUND,
    initialSort: {
      sortKey: 'lastName',
      direction: 'asc' as const
    },
    chainSort: true,
    renderRow: (user: User) => {
      return <tr key={user.id} className={selectedUserId === user.id ? 'selected' : ''}>
        <SelectTableCell itemId={user.id}
                         selected={selectedUserId === user.id}
                         onChange={() => handleItemSelect(user.id)}/>
        <td>{user.lastName}</td>
        <td>{user.firstName}</td>
        <td className="text-center">{user.certificationNumber}</td>
      </tr>;
    }
  }), [
    handleItemSelect,
    filteredUsers,
    selectedUserId
  ]);

  return (
    <Formik initialValues={initialValues}
            validationSchema={Yup.object().shape({})}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal isOpen={isOpen}
               size="xl"
               toggle={() => handleClose()}>
          <ModalHeader toggle={() => handleClose()}>Log User into Escrow</ModalHeader>
          <Form autoComplete="off">
            <ModalBody>
              {!isLoading && <Row>
                <Col>
                  <FormikSearchInput disabled={false}
                                     onSubmit={setSearchText}/>
                  <Form onSubmit={formikProps.handleSubmit}
                        autoComplete="off">
                    <CustomTable {...tableProps}/>
                  </Form>
                </Col>
              </Row>}
            </ModalBody>
            <ModalFooter>
              <Button color="success"
                      className="mr-2"
                      onClick={formikProps.submitForm}
                      disabled={selectedUserId === null || formikProps.isSubmitting}>
                Save
              </Button>
              <Button color="secondary"
                      onClick={() => handleClose()}
                      disabled={formikProps.isSubmitting}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default memo(EscrowApplyOnBehalfModal);