import {memo, useCallback, useMemo, useState} from 'react';
import {Button, Col, Form, FormFeedback, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikErrors, FormikHelpers, FormikProps} from 'formik';

import {FormikDateInput, FormikInput, FormikSelect, useAlerts} from '@reasoncorp/kyber-js';
import * as messages from '../../messages';
import {paymentApi} from '../../api';
import {paymentConfirmationFormSchema} from '../../schema';
import {Payment, PaymentConfirmationFormFields} from '../../types';

type Props = {
  isOpen: boolean
  payment: Payment
  onToggle: (payment?: Payment) => void
}

const fees = [0, 50, 100, 175, 350];

const types: {[key: string]: {value: string, displayValue: string}} = Object.freeze({
  ONLINE: {value: 'ONLINE', displayValue: 'Online'},
  MAILED: {value: 'MAILED', displayValue: 'Mailed'},
  WAIVED: {value: 'WAIVED', displayValue: 'Waived'}
});

const PaymentConfirmationModal = ({
                                    isOpen,
                                    payment,
                                    onToggle
                                  }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [showPaymentDiscrepancyError, setShowPaymentDiscrepancyError] = useState(false);

  const initialValues: PaymentConfirmationFormFields = useMemo(() => ({
    fullName: payment.ssoUser.fullName ?? '',
    type: payment.type ?? '',
    fee: payment.fee ?? 0,
    paymentDate: payment.paymentDate,
    notes: payment.notes ?? ''
  }), [
    payment
  ]);

  const confirmPayment = useCallback(async (values: PaymentConfirmationFormFields,
                                            formikHelpers: FormikHelpers<PaymentConfirmationFormFields>) => {
    try {
      const newPayment = await paymentApi.confirm(payment.id, values);
      formikHelpers.setSubmitting(false);
      showSuccessAlert(messages.PAYMENTS_CONFIRMATION_SUCCESSFUL);
      onToggle(newPayment);
    } catch (error: any) {
      const errorWithType = error as {
        status: number,
        validationMessages?: FormikErrors<PaymentConfirmationFormFields>,
        exception?: string | string[]
      };

      formikHelpers.setSubmitting(false);
      if (errorWithType.status === 422 && errorWithType.validationMessages) {
        formikHelpers.setErrors(errorWithType.validationMessages);
      } else if (errorWithType.exception && errorWithType.exception.includes('PaymentDiscrepancyException')) {
        setShowPaymentDiscrepancyError(true);
      } else {
        showErrorAlert(messages.PAYMENTS_CONFIRMATION_FAILED);
      }
    }
  }, [
    onToggle,
    payment.id,
    showSuccessAlert,
    showErrorAlert
  ]);

  const removePaymentConfirmation = useCallback(async (formikHelpers: FormikHelpers<PaymentConfirmationFormFields>) => {
    try {
      const newPayment = await paymentApi.removeConfirmation(payment.id);
      formikHelpers.setSubmitting(false);
      showSuccessAlert(messages.PAYMENTS_CONFIRMATION_REMOVE_SUCCESSFUL);
      onToggle(newPayment);
    } catch (error) {
      formikHelpers.setSubmitting(false);
      showErrorAlert(messages.PAYMENTS_CONFIRMATION_REMOVE_FAILED);
      onToggle();
    }
  }, [
    onToggle,
    payment.id,
    showSuccessAlert,
    showErrorAlert
  ]);

  const handleSubmit = useCallback(async (values: PaymentConfirmationFormFields,
                                          formikHelpers: FormikHelpers<PaymentConfirmationFormFields>) => {
    if (payment.confirmedOn) {
      await removePaymentConfirmation(formikHelpers);
    } else {
      await confirmPayment(values, formikHelpers);
    }
  }, [
    confirmPayment,
    payment.confirmedOn,
    removePaymentConfirmation
  ]);

  const handleClose = useCallback((formikProps: FormikProps<PaymentConfirmationFormFields>) => {
    formikProps.resetForm();
    onToggle();
  }, [
    onToggle
  ]);

  const handleTypeChange = useCallback((type: string, formikProps: FormikProps<any>) => {
    if (type === types.WAIVED.value) {
      void formikProps.setFieldValue('fee', 0);
    }
  }, []);

  const renderTypeOption = useCallback((key: string) => {
    return <option key={key} value={types[key].value}>{types[key].displayValue}</option>;
  }, []);

  const renderFeeOption = useCallback((fee: number,
                                       formikProps: FormikProps<any>) => {
    // Don't show 0 option unless the payment type is waived
    if (fee === 0 && formikProps.values.type !== types.WAIVED.value) {
      return null;
    } else {
      return <option key={fee} value={fee}>$ {fee}</option>;
    }
  }, []);

  return (
    <Formik initialValues={initialValues}
            enableReinitialize
            validationSchema={paymentConfirmationFormSchema}
            onSubmit={handleSubmit}>
      {(formikProps) => {
        return (
          <Modal isOpen={isOpen}
                 className="PaymentConfirmationModal"
                 autoFocus={false}
                 backdrop="static"
                 size="lg"
                 toggle={() => handleClose(formikProps)}>
            <ModalHeader toggle={() => handleClose(formikProps)}>
              {!payment.confirmedOn ? 'Confirm Payment' : 'Remove Confirmation'}
            </ModalHeader>
            <Form onSubmit={formikProps.handleSubmit}
                  autoComplete="off">
              <ModalBody>
                <Row>
                  <Col md="3">
                    <FormikInput name="fullName"
                                 labelText="User Name"
                                 aria-required={true}
                                 disabled={true}/>
                  </Col>
                  <Col md="3">
                    <FormikSelect name="type"
                                  labelText="Type"
                                  ariaLabel="Payment Type"
                                  disabled={payment.confirmedOn !== null}
                                  autoFocus
                                  aria-required={true}
                                  onChange={(e) => {
                                    handleTypeChange(e.target.value, formikProps);
                                  }}>
                      <option value="">Select</option>
                      {Object.keys(types).map(renderTypeOption)}
                    </FormikSelect>
                  </Col>
                  <Col md="3">
                    <FormikSelect name="fee"
                                  labelText="Fee"
                                  aria-required={true}
                                  disabled={payment.confirmedOn !== null || formikProps.values.type === types.WAIVED.value}>
                      <option value="">Select</option>
                      {fees.map((fee) => renderFeeOption(fee, formikProps))}
                    </FormikSelect>
                  </Col>
                  <Col md="3">
                    <FormikDateInput name="paymentDate"
                                     labelText="Payment Date"
                                     maxDate={new Date()}
                                     aria-required={true}
                                     disabled={payment.confirmedOn !== null}/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormikInput name="notes"
                                 type="textarea"
                                 labelText="Notes"
                                 maxLength={400}
                                 rows={2}
                                 disabled={payment.confirmedOn !== null}/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormFeedback style={{display: showPaymentDiscrepancyError ? 'block' : 'none'}}>
                      {messages.PAYMENT_DISCREPANCY_EXCEPTION}
                    </FormFeedback>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                {!payment.confirmedOn && <Button color="success"
                                                 onClick={formikProps.submitForm}
                                                 disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                  Confirm
                </Button>}
                {payment.confirmedOn && <Button color="danger"
                                                onClick={formikProps.submitForm}
                                                disabled={formikProps.isSubmitting}>
                  Remove Payment
                </Button>}
                <Button color="secondary"
                        onClick={() => handleClose(formikProps)}
                        disabled={formikProps.isSubmitting}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default memo(PaymentConfirmationModal);