import * as Yup from 'yup';

import {YupFile} from '@reasoncorp/kyber-js';

import * as messages from '../messages';

export default Yup.object().shape({
  proofOfCompletionFile: new YupFile()
    .acceptedFileTypes(['PDF', 'PNG', 'JPG', 'JPEG'], messages.BAD_FILE_FORMAT)
    .maxFileSize(10000000, messages.MAX_FILE_SIZE)
    .schema()
    .required(messages.REQUIRED)
});