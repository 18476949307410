import {memo, useCallback, useState} from 'react';

import {ConfirmationModal, useAlerts} from '@reasoncorp/kyber-js';

import {escrowApi} from '../../api';
import * as messages from '../../messages';
import {Escrow} from '../../types';

type Props = {
  isOpen: boolean
  setOpen: (open: false) => void
  escrow: Escrow
  onConfirm: (updatedEscrow: Escrow) => void
}

const EscrowRemoveModal = ({
                             isOpen,
                             setOpen,
                             escrow,
                             onConfirm
                           }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const updatedEscrow = await escrowApi.removeFromEscrow(escrow.id);
      onConfirm(updatedEscrow);
      showSuccessAlert(messages.ESCROW_REMOVED_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.ESCROW_REMOVED_FAILED);
    } finally {
      setIsSubmitting(false);
      setOpen(false);
    }
  }, [
    escrow,
    setOpen,
    onConfirm,
    showErrorAlert,
    showSuccessAlert
  ]);

  return (
    <ConfirmationModal isOpen={isOpen}
                       title="Remove Escrow"
                       confirmButtonText="Remove Escrow"
                       confirmButtonColor="danger"
                       confirmCallback={handleConfirm}
                       cancelCallback={() => setOpen(false)}
                       confirmButtonDisabled={isSubmitting}
                       cancelButtonDisabled={isSubmitting}>
      Are you sure you want to remove <span className="text-danger">{escrow?.name}</span> from escrow?
    </ConfirmationModal>
  );
};

export default memo(EscrowRemoveModal);