import {memo, useCallback, useMemo, useState} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikErrors, FormikHelpers, FormikProps} from 'formik';
import {times} from 'lodash';

import {
  FormikCheckboxGroup,
  FormikDateInput,
  FormikFileInput,
  FormikInput,
  FormikRadioGroup,
  FormikSelect,
  useAlerts
} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {courseApi} from '../../api';
import {Course, CourseFormFields} from '../../types';
import {courseFormSchema} from '../../schema';

type Props = {
  isOpen: boolean
  operation: 'Add' | 'Edit'
  isDuplicating: boolean
  certificationYears: number[]
  course: Course | undefined
  onImportSubmit: (newCourses: Course[]) => void
  onDuplicateClick: () => void
  onToggle: (operation: 'Add' | 'Edit', course?: Course) => void
}

const CourseModal = ({
                       isOpen,
                       operation,
  isDuplicating,
                       certificationYears,
                       course,
                       onImportSubmit,
                       onDuplicateClick,
                       onToggle
                     }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [shouldShowSingleCourseForm, setShouldShowSingleCourseForm] = useState(true);

  const initialValues: CourseFormFields = useMemo(() => ({
    singleOrMultipleCourses: shouldShowSingleCourseForm ? 'single' : 'multiple',
    file: null,
    certificationYear: course?.certificationYear ?? '',
    courseNumber: course?.courseNumber ?? '',
    name: course?.name ?? '',
    courseDate: course?.courseDate ?? null,
    approvedHours: course?.approvedHours ?? '',
    instructor: course?.instructor ?? '',
    organization: course?.organization ?? '',
    contact: course?.contact ?? '',
    locked: course?.locked ?? false,
    stcUpdate: course?.stcUpdate ?? null,
    classFormat: course?.classFormat ?? null
  }), [
    course,
    shouldShowSingleCourseForm
  ]);

  const handleSubmit = useCallback(async (courseChangeRequest: CourseFormFields,
                                          formikHelpers: FormikHelpers<CourseFormFields>) => {
    try {
      if (!shouldShowSingleCourseForm) {
        const formData = new FormData();
        formData.set('file', courseChangeRequest.file as File);
        const newCourses = await courseApi.importCourses(formData);
        onImportSubmit(newCourses);
        showSuccessAlert(messages.COURSES_UPLOAD_SUCCESSFUL);
        setShouldShowSingleCourseForm(true);
      } else if (operation === 'Add') {
        const newCourse = await courseApi.create(courseChangeRequest);
        onToggle(operation, newCourse);
        showSuccessAlert(messages.COURSE_ADD_SUCCESSFUL);
      } else {
        const editedCourse = await courseApi.update(course?.id ?? -1, courseChangeRequest);
        onToggle(operation, editedCourse);
        showSuccessAlert(messages.COURSE_EDIT_SUCCESSFUL);
      }
      formikHelpers.resetForm();
    } catch (error: any) {
      const errorWithType = error as {status: number, validationMessages: FormikErrors<CourseFormFields>};
      if (errorWithType.status === 422 && shouldShowSingleCourseForm) {
        // Will come back from the API by virtue of Spring validation messages
        formikHelpers.setErrors(errorWithType.validationMessages);
      } else if (errorWithType.status === 422 && !shouldShowSingleCourseForm) {
        formikHelpers.setFieldError('file', messages.COURSES_UPLOAD_INVALID);
      } else {
        if (!shouldShowSingleCourseForm) {
          showErrorAlert(messages.COURSES_UPLOAD_FAILURE);
        } else if (operation === 'Add') {
          showErrorAlert(messages.COURSE_ADD_FAILED);
        } else {
          showErrorAlert(messages.COURSE_EDIT_FAILED);
        }
      }
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [
    operation,
    course,
    onToggle,
    onImportSubmit,
    shouldShowSingleCourseForm,
    showErrorAlert,
    showSuccessAlert
  ]);

  const handleClose = useCallback((formikProps: FormikProps<CourseFormFields>) => {
    formikProps.resetForm();
    setShouldShowSingleCourseForm(true);
    onToggle(operation);
  }, [
    onToggle,
    operation
  ]);

  const renderSingleCourseForm = useMemo(() => () => {
    const approvedHourOptions = times(16, (index) => {
      return <option value={index + 1}>{index + 1}</option>;
    });

    return <>
      <Row>
        <Col md="4">
          <FormikSelect name="certificationYear"
                        labelText="Certification Year"
                        autoFocus
                        aria-required={true}>
            <option value="">Select</option>
            {certificationYears.map(certificationYear =>
              <option value={certificationYear}>{certificationYear}</option>)}
          </FormikSelect>
        </Col>
        <Col md="4">
          <FormikSelect name="stcUpdate"
                        labelText="STC Updates"
                        aria-required={true}>
            <option value="">Select</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </FormikSelect>
        </Col>
        <Col md="4">
          <FormikSelect name="classFormat"
                        labelText="Class Format"
                        aria-required={true}>
            <option value="">Select</option>
            <option value="LIVE_SESSION">Live Session</option>
            <option value="SELF_PACED">Self-paced</option>
          </FormikSelect>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormikInput name="courseNumber"
                       labelText="Course Number"
                       aria-required={true}/>
        </Col>
        <Col md="6">
          <FormikInput name="name"
                       labelText="Course Name"
                       aria-required={true}/>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormikDateInput name="courseDate"
                           labelText="Course Date"/>
        </Col>
        <Col md="4">
          <FormikSelect name="approvedHours"
                        labelText="Course Hours"
                        aria-required={true}>
            <option value="">Select</option>
            {approvedHourOptions}
          </FormikSelect>
        </Col>
        <Col md="4">
          <FormikInput name="instructor"
                       labelText="Instructor"
                       aria-required={true}/>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormikInput name="organization"
                       labelText="Organization"
                       aria-required={true}/>
        </Col>
        <Col md="6">
          <FormikInput name="contact"
                       labelText="Contact Information"/>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormikCheckboxGroup type="switch"
                               checkboxes={[
                                 {name: 'locked', labelText: 'Locked'}
                               ]}/>
        </Col>
      </Row>
    </>;
  }, [
    certificationYears
  ]);

  const renderImportCoursesForm = useMemo(() => () => {
    return <Row>
      <Col>
        <FormikFileInput name="file"
                         aria-required={true}
                         labelText="Upload course list"/>
      </Col>
    </Row>;
  }, []);

  return (
    <Formik initialValues={initialValues}
            enableReinitialize
            validateOnMount={true}
            validationSchema={courseFormSchema(shouldShowSingleCourseForm)}
            onSubmit={handleSubmit}>
      {(formikProps) => {
        return (
          <Modal className="CourseModal"
                 autoFocus={false}
                 backdrop="static"
                 size="xl"
                 isOpen={isOpen}
                 toggle={() => handleClose(formikProps)}>
            <ModalHeader toggle={() => handleClose(formikProps)}>
              {operation === 'Add' ? 'Add Course' : 'Edit Course'}
            </ModalHeader>
            <Form onSubmit={formikProps.handleSubmit}
                  autoComplete="off">
              <ModalBody>
                {operation === 'Add' && <Row>
                  <Col>
                    <FormikRadioGroup name="singleOrMultipleCourses"
                                      onChange={(e) => setShouldShowSingleCourseForm(e.target.value === 'single')}
                                      radioButtons={[
                                        {labelText: 'Add a single course', value: 'single'},
                                        {labelText: 'Add multiple courses', value: 'multiple'}
                                      ]}
                                      disabled={isDuplicating}
                                      inline
                                      aria-required
                                      labelText="Select one of the following"/>
                  </Col>
                </Row>}
                {shouldShowSingleCourseForm && renderSingleCourseForm()}
                {!shouldShowSingleCourseForm && renderImportCoursesForm()}
              </ModalBody>
              <ModalFooter className="pr-0 pl-0">
                <Row className="w-100 d-flex m-0">
                  <Col className="col-6">
                    {operation === "Edit" && <Button color="primary"
                            onClick={onDuplicateClick}
                            disabled={!formikProps.isValid || formikProps.isSubmitting}>
                      Duplicate
                    </Button>}
                  </Col>
                  <Col className="col-6 d-flex justify-content-end">
                    <Button color="success"
                            onClick={formikProps.submitForm}
                            className="mr-2"
                            disabled={!formikProps.isValid || formikProps.isSubmitting}>
                      {operation === 'Add' ? 'Add' : 'Save'}
                    </Button>
                    <Button color="secondary"
                            onClick={() => handleClose(formikProps)}
                            disabled={formikProps.isSubmitting}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default memo(CourseModal);
