import {memo, useCallback, useMemo} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import {FormikMultipleFileInput, StatefulButton, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {fileAndDocumentApi} from '../../api';
import {Escrow, EscrowUploadFormFields} from '../../types';
import {escrowAdditionalUploadsSchema} from '../../schema';

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  escrowId: number
  setEscrow: (escrow: Escrow) => void
}

const EscrowAdditionalUploadsModal = ({
                                        isOpen,
                                        setOpen,
                                        escrowId,
                                        setEscrow
                                      }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();

  const initialValues: EscrowUploadFormFields = useMemo(() => ({
    files: []
  }), []);

  const handleSubmit = useCallback(async (values: EscrowUploadFormFields,
                                          formikHelpers: FormikHelpers<EscrowUploadFormFields>) => {
    try {
      const formData = new FormData();
      values.files.forEach(file => formData.append('files', file));
      const escrow = await fileAndDocumentApi.createEscrowUploads(escrowId, formData);
      setEscrow(escrow);
      showSuccessAlert(messages.ESCROW_ADDITIONAL_UPLOADS_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.ESCROW_ADDITIONAL_UPLOADS_FAILED);
    } finally {
      formikHelpers.resetForm();
      setOpen(false);
    }
  }, [
    escrowId,
    setOpen,
    setEscrow,
    showErrorAlert,
    showSuccessAlert
  ]);

  const handleClose = useCallback((formikProps: FormikProps<EscrowUploadFormFields>) => {
    formikProps.resetForm();
    setOpen(false);
  }, [
    setOpen
  ]);

  return (
    <Formik initialValues={initialValues}
            validationSchema={escrowAdditionalUploadsSchema}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal isOpen={isOpen}
               toggle={() => handleClose(formikProps)}>
          <ModalHeader toggle={() => handleClose(formikProps)}>Add Additional Documents</ModalHeader>
          <Form autoComplete="off">
            <ModalBody>
              <Row>
                <Col>
                  <p className="text-muted">
                    Upload Additional Supporting Documentation
                  </p>
                  <FormikMultipleFileInput name="files"/>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <StatefulButton activeState={formikProps.isSubmitting ? 'submitting' : 'default'}
                              states={[
                                {name: 'default', text: 'Submit'},
                                {name: 'submitting', text: 'Submitting', icon: 'spinner', spinIcon: true}
                              ]}
                              color="success"
                              aria-label="Submit Button"
                              onClick={formikProps.submitForm}
                              disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
              </StatefulButton>
              <Button color="secondary"
                      onClick={() => handleClose(formikProps)}
                      disabled={formikProps.isSubmitting}
                      aria-label="Cancel Button">
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default memo(EscrowAdditionalUploadsModal);