import {memo, useCallback, useMemo} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikProps} from 'formik';

import {FormikInput, FormikRadioGroup, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {escrowApi} from '../../api';
import {Escrow, EscrowReviewFormFields} from '../../types';
import {EscrowStatus} from '../../enum';
import {escrowReviewFormSchema} from '../../schema';

type Props = {
  isOpen: boolean
  setOpen: (open: false) => void
  escrow: Escrow
  onSave: (updatedEscrow: Escrow) => void
}

const EscrowReviewModal = ({
                             isOpen,
                             setOpen,
                             escrow,
                             onSave
                           }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();

  const initialValues: EscrowReviewFormFields = useMemo(() => ({
    status: '',
    comments: ''
  }), []);

  const handleSubmit = useCallback(async (formikValues: EscrowReviewFormFields) => {
    const valuesToSubmit = {...formikValues};
    const isApproving = valuesToSubmit.status === EscrowStatus.APPROVED;

    if (isApproving) {
      valuesToSubmit.comments = '';
    }

    try {
      const updatedEscrow = isApproving ?
        await escrowApi.approve(escrow.id) :
        await escrowApi.deny(escrow.id, valuesToSubmit.comments);

      onSave(updatedEscrow);

      showSuccessAlert(
        isApproving ?
          messages.ESCROW_APPROVED_SUCCESSFUL :
          messages.ESCROW_DENIED_SUCCESSFUL
      );
    } catch (error) {
      showErrorAlert(
        isApproving ?
          messages.ESCROW_APPROVED_FAILED :
          messages.ESCROW_DENIED_FAILED
      );
    } finally {
      setOpen(false);
    }
  }, [
    escrow,
    setOpen,
    onSave,
    showErrorAlert,
    showSuccessAlert
  ]);

  const handleClose = useCallback((formikProps: FormikProps<EscrowReviewFormFields>) => {
    formikProps.resetForm();
    setOpen(false);
  }, [
    setOpen
  ]);

  return <Formik initialValues={initialValues}
                 validationSchema={escrowReviewFormSchema}
                 onSubmit={handleSubmit}>
    {(formikProps) => (
      <Modal isOpen={isOpen}
             toggle={() => handleClose(formikProps)}
             backdrop="static">
        <ModalHeader toggle={() => handleClose(formikProps)}>Review Escrow</ModalHeader>
        <Form autoComplete="off">
          <ModalBody>
            <Row>
              <Col>
                <p>
                  Do you want to approve or deny <span className="text-danger">{escrow?.name}</span> for escrow?
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikRadioGroup name="status"
                                  aria-label="Approve Escrow"
                                  formGroupClass="larger-label"
                                  inline
                                  radioButtons={[
                                    {value: EscrowStatus.APPROVED, labelText: 'Approved'},
                                    {value: EscrowStatus.DENIED, labelText: 'Denied'}
                                  ]}/>
              </Col>
            </Row>
            {formikProps.values.status === EscrowStatus.DENIED &&
              <Row>
                <Col>
                  <FormikInput type="textarea"
                               name="comments"
                               labelText="Please provide a reason for denial."
                               aria-label="Please provide a reason for denial."
                               maxLength="200"/>
                </Col>
              </Row>
            }
          </ModalBody>
          <ModalFooter>
            <Button color="success"
                    className="mr-1"
                    onClick={formikProps.submitForm}
                    disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={() => handleClose(formikProps)}
                    disabled={formikProps.isSubmitting}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )}
  </Formik>;
};

export default memo(EscrowReviewModal);